<div class="dropdown-wrapper" [attr.search]="search ? '' : null">
    <mat-form-field appearance="fill" class="dropdown-form-field">
        <mat-label>{{label | translate}}</mat-label>
        
        <span class="icon-prefix"  matPrefix>
          <ng-container [ngTemplateOutlet]="searchIcon"  />
        </span>
        <mat-select class="dropdown-select" [attr.testId]="testId"   [formControl]="control" [multiple]="multiSelection" [panelWidth]="maxContentSelectionPanel ? null : 'auto'">

          @if(search){
            <mat-option class="ngx-wrapper-search">
              <ngx-mat-select-search
                [showToggleAllCheckbox]="multiSelection"
                [toggleAllCheckboxIndeterminate]="isIndeterminate()"
                [toggleAllCheckboxChecked]="isChecked()"
                (toggleAll)="toggleSelectAll($event)"

                class="ngx-search"
                [formControl]="searchControl"
                [clearSearchInput]="false"
                [searching]="isSearchResultLoading"
                placeholderLabel="{{ 'general.actions.Search' | translate }}..."
                noEntriesFoundLabel="{{ 'general.labels.Empty' | translate }}">
              </ngx-mat-select-search>
            </mat-option>
          }  
          
          @if (!multiSelection) {
            <mat-option></mat-option>
          }

          @for (option of filteredOptions(); track $index) {
                <mat-option [value]="putValue(option)">{{showLabel(option, labelProperty) | translate}}</mat-option>
          }
        </mat-select>
        <mat-error>
          @if(control?.errors){
          <app-validation-message [errors]="control.errors"></app-validation-message>
          }
        </mat-error>
    </mat-form-field>
</div>

<ng-template #searchIcon>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
</ng-template>