import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  Host,
  inject,
  Input,
  model,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  signal,
  SimpleChanges,
  SkipSelf,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { debounce, Observable, timer } from 'rxjs';
import {
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
  IFormDropdownOption,
} from '../../form-controls/form-controls.const';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-form-input-dropdown-nd',
  templateUrl: './app-form-input-dropdown-nd.component.html',
  styleUrls: ['./app-form-input-dropdown-nd.component.scss'],
  standalone: true,
  imports: [
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    TranslateModule,
    NgTemplateOutlet,
    ValidationMessageComponent,
    MatFormFieldModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputDropdownNdComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input({
    required: true,
    transform: (value: string) => {
      return 'checkbox-input-' + value;
    },
  })
  testId: string;
  @Input() label: string = '';

  @Input() controlName: string;
  @Input() control: AbstractControl;

  @Input() disabled: boolean = false;

  @Input() frontendFiltering = false;
  @Input() frontendFilteringProperty = 'value';

  @Input() objectAsValue = false;
  @Input() valueProperty: string = 'value';
  @Input() labelProperty: string = 'id';
  @Input() optionDisplayFunction: Function = (option) => {
    return option[this.labelProperty];
  };
  @Input() maxContentSelectionPanel = false;

  //- OPTIONS
  options = model<IFormDropdownOption[]>([]);
  missingValues = signal([]);
  @Input() set options$(options$: Observable<IFormDropdownOption[]>) {
    if (options$) {
      options$
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe((options) => {
          this.options.set(options);
        });
    }
  }
  @Input() set listOptions(list: any[]) {
    const mappedList = list.map((value) => {
      return { value, label: value };
    });
    this.options.set(mappedList);
  }
  filteredOptions = computed(() => {
    const options = this.getFilteredOptions();
    const formattedOptions = this.getFormattedOptions(options);
    this.isSearchResultLoading = false;
    return formattedOptions;
  });

  @Input() multiSelection = false;
  @Input() selectAll = false;
  @Input() selectSingle = false;

  //-SEARCH
  @Input() search: boolean = false;
  searchControl = new FormControl();
  isSearchResultLoading: boolean = false;
  searchSignal = signal<string>('');
  @Output() filterChanged = new EventEmitter<string>();

  readonly #destroyRef = inject(DestroyRef);
  isIndeterminate = signal(false);
  isChecked = signal(false);

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        debounce(() =>
          this.frontendFiltering
            ? timer(0)
            : timer(+DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        ),
      )
      .subscribe((value) => {
        this.isSearchResultLoading = true;
        if (this.search && !this.frontendFiltering)
          this.filterChanged.emit(value);
        else this.searchSignal.set(value);
      });
    if (!this.control && this.controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    }
    if (this.control && this.disabled) {
      this.control.disable();
    }
    if (this.control.value) this.assignMissingValues();

    this.onOverlayToggle(true);
  }

  ngAfterViewInit(): void {
    if (this.multiSelection && this.control.value && !this.objectAsValue) {
      this.control.setValue([this.control.value[0]?.value]);
    }
    if (
      this.control &&
      !this.control.value &&
      this.selectSingle &&
      this.options().length === 1
    ) {
      this.control.setValue(this.filteredOptions()[0].value);
    } else if (
      this.control &&
      !this.control.value &&
      this.selectAll &&
      this.options().length > 0
    ) {
      const value = this.objectAsValue
        ? this.filteredOptions()
        : this.filteredOptions().map((option) => option.value);
      this.isChecked.set(true);
      this.control.setValue(value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.isSearchResultLoading = false;
    }
  }

  ngOnDestroy() {
    this.onOverlayToggle(false);
  }

  assignMissingValues() {
    if (Array.isArray(this.control.value)) {
      this.missingValues.set([...this.control.value]);
    } else {
      this.missingValues.set([this.control.value]);
    }
  }

  showLabel(option, text: string) {
    return this.objectAsValue ? option?.[text] : option?.['label'];
  }

  putValue(option) {
    return this.objectAsValue ? option : option?.['value'];
  }

  getFilteredOptions() {
    const options = [...new Set([...this.missingValues(), ...this.options()])];

    if (this.frontendFiltering) {
      return options.filter((option) => {
        const filterValue = this.searchSignal();
        return option[this.frontendFilteringProperty].includes(filterValue);
      });
    }

    return options;
  }

  getFormattedOptions(options) {
    if (this.objectAsValue) {
      return options;
    }

    return options.map((option) => ({
      value: option[this.valueProperty],
      label: this.labelProperty
        ? option[this.labelProperty]
        : this.optionDisplayFunction(option),
    }));
  }

  onOverlayToggle(e: boolean) {
    const appRoot = document.querySelector('app-root');

    if (e && appRoot) {
      appRoot.classList.add('dropdown');
    } else if (appRoot) {
      appRoot.classList.remove('dropdown');
    }
  }

  toggleSelectAll(e: boolean) {
    const value = this.objectAsValue
      ? this.filteredOptions()
      : this.filteredOptions().map((option) => option.value);
    if (e) {
      this.control.setValue(value);
    } else {
      this.control.setValue([]);
    }
  }
}
