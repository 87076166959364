import {
  ChangeDetectionStrategy,
  Component,
  Host,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-form-input-checkbox-nd',
  templateUrl: './app-form-input-checkbox-nd.component.html',
  styleUrls: ['./app-form-input-checkbox-nd.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ValidationMessageComponent,
    MatCheckboxModule,
    MatFormFieldModule,
  ],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputCheckBoxNdComponent {
  @Input({
    required: true,
    transform: (value: string) => {
      return 'checkbox-input-' + value;
    },
  })
  testId: string;
  @Input() label: string = '';
  @Input() hint: string;
  @Input() alignHint: 'start' | 'end' = 'start';

  @Input() size: 'md-size' | 'sm-size' = 'sm-size';

  @Input() disabled: boolean = false;

  @Input() labelPosition: string = 'after';

  @Input() controlName: string;
  @Input() control: AbstractControl;

  readonly requiredTrue = Validators.requiredTrue;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    if (!this.control && this.controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    }
    if (this.control && this.disabled) {
      this.control.disable();
    }
  }
}
