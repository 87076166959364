import { NgModule } from '@angular/core';

import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { FormInputTextAreaNdComponent } from './form-input-text-area-nd/app-form-input-text-area-nd.component';
import { FormInputCheckBoxNdComponent } from './form-input-checkbox-nd/app-form-input-checkbox-nd.component';
import { FormInputTextNdComponent } from './form-input-text-nd/app-form-input-text-nd.component';
import { FormInputRadioNdComponent } from './form-input-radio-nd/app-form-input-radio-nd.component';
import { FormInputDropdownNdComponent } from './form-input-dropdown/app-form-input-dropdown-nd.component';

const moduleComponents = [
  FormInputTextNdComponent,
  ValidationMessageComponent,
  FormInputTextAreaNdComponent,
  FormInputCheckBoxNdComponent,
  FormInputRadioNdComponent,
  FormInputDropdownNdComponent,
];
@NgModule({
  imports: [...moduleComponents],
  bootstrap: [],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
  schemas: [],
})
export class FormControlsNdModule {}
